<template>
    <div>
        <GameCard1 title="任务：浏览文章" actions="具体任务：如不熟练，浏览文章即可。" />
        <el-divider></el-divider>
        <MarkdownRenderer :markdown="markdownContent" />
    </div>
</template>

<script>
import { defineComponent } from "vue";
import GameCard1 from "../../base/GameCard1.vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
    components: {
        MarkdownRenderer,
        GameCard1,
    },
    data() {
        return {
            markdownContent: `
            `,
        };
    },
});
</script>

<style scoped></style>