<template>
    <div>
        <GameCard1 title="任务：理解文章" actions="具体任务：请确保理解本文内容。" />
        <el-divider></el-divider>
        <MarkdownRenderer :markdown="markdownContent" />
    </div>
</template>

<script>
import { defineComponent } from "vue";
import GameCard1 from "../../base/GameCard1.vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
    components: {
        MarkdownRenderer,
        GameCard1,
    },
    data() {
        return {
            markdownContent: `
  ### 1. Kubernetes 简介
  
  #### 什么是 Kubernetes？
  Kubernetes（简称 K8s）是一个开源的容器编排平台，旨在自动化容器化应用的部署、扩展和管理。它最初由 Google 设计，并现已成为云原生应用的标准。Kubernetes 提供了强大的功能来协调成千上万个容器，使其能够高效、稳定地运行。
  
  **主要特点：**
  - **自动化部署与管理**：Kubernetes 可以自动部署、监控和管理容器化应用，确保其高可用性。
  - **弹性伸缩**：支持基于 CPU、内存等指标的自动扩展，能够根据负载情况调整应用实例的数量。
  - **服务发现与负载均衡**：Kubernetes 内部提供 DNS 解析和服务发现能力，自动管理请求的负载均衡。
  - **滚动更新与回滚**：允许应用程序无缝更新，并在更新失败时回滚到稳定版本。
  - **存储编排**：支持持久存储管理，如 NFS、Ceph、GlusterFS 和云存储。
  - **自愈能力**：当容器崩溃或节点故障时，Kubernetes 会自动重新调度和恢复应用。
  
  #### Kubernetes 的核心组件
  1. **Master 组件**：
     - \`kube- apiserver\`：提供 Kubernetes API 接口，供用户和其他组件交互。  
     端口: 6443 (HTTPS)：Kubernetes API Server 的安全端口，用于接收和处理来自客户端和其他组件的请求。  
     端口: 8080 (HTTP)：Kubernetes API Server 的非安全端口，通常用于本地测试和调试。

     - \`etcd\`：分布式键值存储，存储 Kubernetes 集群的所有数据。  
    端口:  2379：etcd 客户端用于与 etcd 服务器通信的端口。  
    端口: 2380：etcd 集群中节点之间通信的端口。  
     - \`kube - scheduler\`：负责将 Pod 分配到合适的节点。  
     端口: 10251：kube-scheduler 与 kube-apiserver 通信的端口。
     - \`kube - controller - manager\`：运行控制器（如副本控制器、节点控制器），保证集群的期望状态。  
    端口: 10252：kube-controller-manager 与 kube-apiserver 通信的端口。
  2. **Node 组件**：
     - \`kubelet\`：负责与 Master 交互，管理 Pod 生命周期。  
     端口: 10250：kubelet 与 kube-apiserver 通信的端口，用于接收和执行来自 Master 的指令。
     - \`kube - proxy\`：实现 Kubernetes 服务的网络代理。
     - \`Container Runtime\`：如 Docker、containerd 或 CRI-O，负责运行容器。

[K8s官网文档架构图](https://kubernetes.io/zh-cn/docs/concepts/overview/components/#kube-apiserver) (https://kubernetes.io/zh-cn/docs/concepts/overview/components/#kube-apiserver)
  
  #### Kubernetes 的核心概念
  - **Pod (容器的摇篮)**：Pod 是 Kubernetes 中最小的部署单元，它可以包含一个或多个紧密相关的容器。  
  你可以把 Pod 想象成一个“小房子”，里面住着一个或多个“容器居民”。   
  例子:
    *   一个 Pod 里面可以运行一个 Nginx 容器和一个 Redis 容器，它们共享网络和存储，协同工作。  
    *   另一个 Pod 里面可以只运行一个 Java 应用容器。  

  - **Deployment (应用的管家)**：Deployment 负责定义和管理 Pod 的期望状态。它像一个“应用管家”，确保你的应用始终按照你想要的方式运行。  
  它可以创建、更新和删除 Pod，还支持滚动更新和回滚，让你的应用升级更平滑。  
  例子:
    *   你想部署一个 Web 应用，使用 3 个 Pod 来提供服务。你可以创建一个 Deployment 来定义这个应用，Deployment 会自动创建 3 个 Pod，并确保它们始终运行。
    *   当你的应用需要升级时，Deployment 可以帮你实现滚动更新，一次只更新一个 Pod，保证服务不中断。

  - **Service (服务的门卫)**：Service 提供了一个稳定的访问入口，负责负载均衡。它可以将多个 Pod 组织在一起，对外提供一个统一的访问地址。  
  你可以把 Service 想象成一个“门卫”，它站在你的应用前面，负责接收用户的请求，并将请求分发给后端的 Pod。  
  例子:
    *   你的 Web 应用运行在 3 个 Pod 中，每个 Pod 都有自己的 IP 地址。你可以创建一个 Service，它会为你的应用分配一个唯一的 IP 地址和端口，用户可以通过这个地址访问你的应用，Service 会自动将请求分发给后端的 Pod。
    *   Service 还可以实现负载均衡，将用户的请求均匀地分发给不同的 Pod，提高应用的可用性。

  - **ConfigMap & Secret (配置和密钥的保险箱)**：ConfigMap 用于存储非敏感的配置信息，Secret 用于存储敏感数据，如密码、密钥等。它们就像“保险箱”，安全地保管着你的应用所需的各种数据。  
  例子:
    *   你的 Web 应用需要连接到数据库，数据库的地址、用户名和密码可以分别存储在 ConfigMap 和 Secret 中，然后挂载到 Pod 中，供应用使用。
    *   通过 ConfigMap 和 Secret，你可以方便地管理应用的配置信息和敏感数据，而不需要把它们硬编码到应用中。

  - **PersistentVolume (PV) & PersistentVolumeClaim (PVC) (持久化存储)**：PV (PersistentVolume) 代表集群中的一块持久化存储，PVC (PersistentVolumeClaim) 是用户对存储的请求。  
  它们一起为 Pod 提供了持久化存储的能力，即使 Pod 被删除或重新创建，数据也不会丢失。你可以把 PV 想象成“硬盘”，PVC 想象成“硬盘的使用申请”。  
  例子:
    *   你的应用需要存储用户上传的图片，你可以创建一个 PV 来表示一块云存储，然后创建一个 PVC 来申请使用这块存储，最后将 PVC 挂载到 Pod 中，应用就可以在 PV 中存储图片数据了。

    
  - **Ingress (流量的引导者)**： Ingress 提供了 HTTP/HTTPS 访问控制，通常用于暴露服务。  
  它就像一个“交通指挥官”，负责将外部的 HTTP/HTTPS 请求路由到集群内部的 Service。
 

  #### Kubernetes 的优势
  1. **自动化运维**：Kubernetes 能够自动监控、修复和扩展应用程序，减少人工干预。
  2. **高可用性**：提供故障恢复和负载均衡，确保应用稳定运行。
  3. **混合云支持**：Kubernetes 可以运行在公有云、私有云和本地数据中心，实现无缝迁移。
  4. **云原生生态**：支持微服务架构，与 DevOps、CI/CD 完美结合。
  
这里是包含 \`containerd\` 的对比表格：  

### Kubernetes 与 Docker、containerd 的关系

Kubernetes 不是替代 Docker 的工具，而是一个用于管理容器的编排平台。Docker 和 containerd 则是具体的容器运行时（Container Runtime）。

| 特性          | Docker                     | containerd                 | Kubernetes                    |
|--------------|---------------------------|----------------------------|--------------------------------|
| **作用**      | 运行单个容器，提供完整工具链 | 轻量级容器运行时，专注运行容器 | 管理和编排多个容器              |
| **是否包含 CLI** | 提供 \`docker\` CLI        | 无独立 CLI，通常通过 \`ctr\` 或 \`crictl\` 访问 | 通过 \`kubectl\` 进行管理        |
| **镜像管理**  | 提供完整的镜像管理功能      | 支持镜像拉取、存储，但无构建功能 | 通过 CRI 连接 containerd 进行镜像管理 |
| **容器运行时** | 内置 containerd           | 直接负责容器生命周期管理     | 通过 CRI 兼容多个运行时，如 containerd |
| **存储管理**  | 依赖 Docker Volume        | 支持 snapshotter 存储后端   | 提供 PV 和 PVC 管理持久存储       |
| **网络管理**  | 需要手动配置，依赖 CNI     | 依赖 CNI                    | 提供内置的网络管理              |
| **服务发现**  | 依赖额外工具，如 \`docker-compose\` | 本身不提供服务发现功能       | 内置 DNS 和 Service 发现       |
| **自动扩展**  | 需要手动管理               | 本身无编排功能               | 支持自动扩展                   |

总结：
- \`Docker\` 是一个完整的容器平台，包含 CLI、构建、运行、管理等功能。
- \`containerd\` 只负责容器运行，轻量级，适合作为 Kubernetes 的容器运行时。
- \`Kubernetes\` 是编排工具，不直接运行容器，而是通过 CRI 连接 \`containerd\` 或其他容器运行时。

在 Kubernetes 1.24 版本后，已正式移除对 Docker 作为容器运行时的支持（Dockershim 被移除），默认使用 \`containerd\` 或 \`CRI-O\`。

  
  ##### 总结：
  Kubernetes 是现代化云计算的核心技术，它通过自动化管理容器，实现了高效的资源利用率、强大的扩展性和稳定性，是微服务和云原生应用的理想选择。
        `,
        };
    },
});
</script>

<style scoped></style>