<template>
    <div>
        <GameCard1 title="任务：部署K8s" actions="具体任务：使用K3S，部署K8S, 一个Master, 一个Worker。" />
        <el-divider></el-divider>
        <MarkdownRenderer :markdown="markdownContent" />
    </div>
</template>

<script>
import { defineComponent } from "vue";
import GameCard1 from "../../base/GameCard1.vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
    components: {
        MarkdownRenderer,
        GameCard1,
    },
    data() {
        return {
            markdownContent: `
### 1. 使用 K3s 部署轻量级 Kubernetes

#### 为什么选择 K3s？
K3s 是一个轻量级的 Kubernetes 发行版，由 Rancher Labs 开发，专为边缘计算、IoT 和资源受限环境设计。
它相比标准 Kubernetes（K8s）更小巧，安装简单，占用更少的系统资源。

**K3s 主要特点：**
- **轻量级**：仅 50MB 的二进制文件，依赖项少。
- **内置数据库**：默认使用 SQLite 代替 etcd（也可使用外部数据库）。
- **一键安装**：官方提供简单的安装脚本。
- **适用于边缘计算和小规模集群**。

---

### 2. 安装 K3s 并部署 Kubernetes

建议使用【香港站点】，完成本次试验。  (按下Ctrl，点击 **香港站点** 链接，打开新Tab)。

[http://hk.azlinux.com/multi-instances](http://hk.azlinux.com/multi-instances)  
![简单4步](http://cdn.azlinux.com/sre/media/k8s/create_2_node_for_k8s.png)

#### 2.1 在 Master 节点安装 K3s
选择【Ubuntu】【22.04】版本，2核心4GB，实例数量选择【2】，点击【准备环境】，等待约20秒。

在第一台机器（Master 节点）上运行以下命令安装 K3s：

\`\`\`sh
curl -sfL https://get.k3s.io | sh -
\`\`\`

安装完成后，K3s 会自动启动一个 Kubernetes 控制平面，并创建 \`kubectl\` 相关的配置文件。

**查看 Master 节点状态：**

\`\`\`sh
kubectl get nodes
\`\`\`

**获取 Worker 节点加入的 Token：**

\`\`\`sh
cat /var/lib/rancher/k3s/server/node-token
\`\`\`

记录这个 Token，后续 Worker 节点需要用到。

---

#### 2.2 在 Worker 节点加入 K3s 集群
在第二台机器（Worker 节点）上，使用以下命令加入 K3s 集群。

首先准备 Master 节点的内网 IP（K3s API Server 地址）和 Token：

\`\`\`sh
MASTER=172.31.27.154  #请修改为你对应的第一个实例的IP
K3S_TOKEN="YOUR_NODE_TOKEN_HERE"   #请修改为上面cat命令查询到的字符串
curl -sfL https://get.k3s.io | K3S_URL="https://$MASTER:6443" K3S_TOKEN="$K3S_TOKEN" sh -
\`\`\`

**验证 Worker 节点加入成功：**

在 Master 节点（第一个实例上）执行：
\`\`\`sh
kubectl get nodes
\`\`\`
如果看到 Worker 节点出现在列表中，说明加入成功。

---

### 3. 验证 Kubernetes 集群是否正常运行

**检查 Pod 运行状态**
\`\`\`sh
kubectl get pods -A
\`\`\`

**部署一个测试应用（以下为快速部署方式）**
\`\`\`sh
kubectl create deployment nginx --image=nginx
kubectl expose deployment nginx --type=NodePort --port=80
\`\`\`

**获取访问地址：**
\`\`\`sh
kubectl get svc
\`\`\`
通过 \`NodePort\` 的端口访问 Nginx 服务。公网ip地址:NodePort端口

---

### 4. 总结
- K3s 是一个轻量级的 Kubernetes 发行版，适合小规模集群和边缘计算。
- 通过简单的脚本可以快速安装 K3s 并部署 Kubernetes 集群。
- 通过 Master 节点的 Token 让 Worker 节点加入集群。
- 运行 \`kubectl\` 命令检查集群状态，并部署示例应用验证环境。

以上步骤完成后，你已经成功搭建了一个最小化的 Kubernetes 集群，并运行了第一个应用！ 🎉

  `,
        };
    },
});
</script>

<style scoped></style>
