<template>
    <el-container>
        <!-- Sidebar with toggle button -->
        <el-aside :width="isCollapsed ? '50px' : '320px'" class="sidebar">
            <!-- Collapse button -->
            <div class="collapse-button" @click="toggleSidebar">
                <i :class="isCollapsed ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
            </div>

            <!-- Menu items -->
            <el-menu :default-active="activeLink" @select="handleSelect" class="el-menu-vertical-demo"
                background-color="#001f3f" text-color="#fff" active-text-color="#ffd04b">
                <el-menu-item index="KubernetesIntro">
                    <span v-if="!isCollapsed">Kubernetes 介绍</span>
                </el-menu-item>
                <el-menu-item index="KubernetesInstallation">
                    <span v-if="!isCollapsed">Kubernetes 安装 (K3s方式)</span>
                </el-menu-item>
                <el-menu-item index="KubernetesInstallation2">
                    <span v-if="!isCollapsed">Kubernetes 安装 (Kubespray)</span>
                </el-menu-item>
                <el-menu-item index="KubernetesInstallation3">
                    <span v-if="!isCollapsed">Kubernetes 安装 (Kubeadm方式) CKA</span>
                </el-menu-item>
                <el-menu-item index="KubernetesAfterInstallation">
                    <span v-if="!isCollapsed">！请重点阅读官方文档！</span>
                </el-menu-item>
                <el-menu-item index="KubernetesKubectlBasic">
                    <span v-if="!isCollapsed">！本网站主要用于练习！</span>
                </el-menu-item>
                <el-menu-item index="KubernetesFirstPod">
                    <span v-if="!isCollapsed">创建第一个 Pod</span>
                </el-menu-item>
                <el-menu-item index="KubernetesDeployment">
                    <span v-if="!isCollapsed">部署 Deployment</span>
                </el-menu-item>
                <el-menu-item index="KubernetesService">
                    <span v-if="!isCollapsed">Service 及访问应用</span>
                </el-menu-item>
                <el-menu-item index="KubernetesConfigMapSecret">
                    <span v-if="!isCollapsed">ConfigMap & Secret</span>
                </el-menu-item>
                <el-menu-item index="KubernetesStorage">
                    <span v-if="!isCollapsed">持久化存储（PV & PVC）</span>
                </el-menu-item>
                <el-menu-item index="KubernetesResourceLimit">
                    <span v-if="!isCollapsed">资源限制（requests & limits）</span>
                </el-menu-item>
                <el-menu-item index="KubernetesScheduling">
                    <span v-if="!isCollapsed">Pod 调度（亲和性 & 反亲和性）</span>
                </el-menu-item>
                <el-menu-item index="KubernetesNetworking">
                    <span v-if="!isCollapsed">Kubernetes 网络（CNI, Service, Ingress）</span>
                </el-menu-item>
                <el-menu-item index="KubernetesLoggingMonitoring">
                    <span v-if="!isCollapsed">监控与日志</span>
                </el-menu-item>
                <el-menu-item index="KubernetesNodeManagement">
                    <span v-if="!isCollapsed">节点管理（污点 & 容忍度）</span>
                </el-menu-item>
                <el-menu-item index="KubernetesContainerRuntime">
                    <span v-if="!isCollapsed">容器运行时</span>
                </el-menu-item>
                <el-menu-item index="KubernetesBackupRestore">
                    <span v-if="!isCollapsed">Kubernetes 备份与恢复</span>
                </el-menu-item>
                <el-menu-item index="KubernetesTroubleshooting">
                    <span v-if="!isCollapsed">Kubernetes 排错</span>
                </el-menu-item>
            </el-menu>
        </el-aside>

        <!-- Content area -->
        <el-main class="content">
            <component :is="currentComponent" />
        </el-main>
    </el-container>
</template>

<script>
import KubernetesIntro from "./kubernetes/KubernetesIntro.vue";
import KubernetesInstallation from "./kubernetes/KubernetesInstallation.vue";
import KubernetesInstallation2 from "./kubernetes/KubernetesInstallation2.vue";
import KubernetesInstallation3 from "./kubernetes/KubernetesInstallation3.vue";
import KubernetesAfterInstallation from "./kubernetes/KubernetesAfterInstallation.vue";
import KubernetesKubectlBasic from "./kubernetes/KubernetesKubectlBasic.vue";
import KubernetesFirstPod from "./kubernetes/KubernetesFirstPod.vue";
import KubernetesDeployment from "./kubernetes/KubernetesDeployment.vue";
import KubernetesService from "./kubernetes/KubernetesService.vue";
import KubernetesConfigMapSecret from "./kubernetes/KubernetesConfigMapSecret.vue";
import KubernetesStorage from "./kubernetes/KubernetesStorage.vue";
import KubernetesResourceLimit from "./kubernetes/KubernetesResourceLimit.vue";
import KubernetesScheduling from "./kubernetes/KubernetesScheduling.vue";
import KubernetesNetworking from "./kubernetes/KubernetesNetworking.vue";
import KubernetesLoggingMonitoring from "./kubernetes/KubernetesLoggingMonitoring.vue";
import KubernetesNodeManagement from "./kubernetes/KubernetesNodeManagement.vue";
import KubernetesContainerRuntime from "./kubernetes/KubernetesContainerRuntime.vue";
import KubernetesBackupRestore from "./kubernetes/KubernetesBackupRestore.vue";
import KubernetesTroubleshooting from "./kubernetes/KubernetesTroubleshooting.vue";

export default {
    data() {
        return {
            activeLink: "KubernetesIntro", // 默认选中 KubernetesIntro
            currentComponent: "KubernetesIntro", // 默认加载 KubernetesIntro 组件
            isCollapsed: false, // 控制侧边栏是否折叠
        };
    },
    components: {
        KubernetesIntro,
        KubernetesInstallation,
        KubernetesInstallation2,
        KubernetesInstallation3,
        KubernetesAfterInstallation,
        KubernetesKubectlBasic,
        KubernetesFirstPod,
        KubernetesDeployment,
        KubernetesService,
        KubernetesConfigMapSecret,
        KubernetesStorage,
        KubernetesResourceLimit,
        KubernetesScheduling,
        KubernetesNetworking,
        KubernetesLoggingMonitoring,
        KubernetesNodeManagement,
        KubernetesContainerRuntime,
        KubernetesBackupRestore,
        KubernetesTroubleshooting,
    },
    methods: {
        handleSelect(key) {
            this.currentComponent = key;
        },
        toggleSidebar() {
            this.isCollapsed = !this.isCollapsed;
        },
    },
};
</script>