<template>
    <div>
        <GameCard1 title="任务：浏览文章" actions="具体任务：如不熟练，浏览文章即可。" />
        <el-divider></el-divider>
        <MarkdownRenderer :markdown="markdownContent" />
    </div>
</template>

<script>
import { defineComponent } from "vue";
import GameCard1 from "../../base/GameCard1.vue";
import MarkdownRenderer from "../../base/MarkdownRenderer.vue";

export default defineComponent({
    components: {
        MarkdownRenderer,
        GameCard1,
    },
    data() {
        return {
            markdownContent: `
  ### 1. Kubernetes 简介
  
  #### 什么是 Kubernetes？
  Kubernetes（简称 K8s）是一个开源的容器编排平台，旨在自动化容器化应用的部署、扩展和管理。它最初由 Google 设计，并现已成为云原生应用的标准。Kubernetes 提供了强大的功能来协调成千上万个容器，使其能够高效、稳定地运行。
  
  **主要特点：**
  - **自动化部署与管理**：Kubernetes 可以自动部署、监控和管理容器化应用，确保其高可用性。
  - **弹性伸缩**：支持基于 CPU、内存等指标的自动扩展，能够根据负载情况调整应用实例的数量。
  - **服务发现与负载均衡**：Kubernetes 内部提供 DNS 解析和服务发现能力，自动管理请求的负载均衡。
  - **滚动更新与回滚**：允许应用程序无缝更新，并在更新失败时回滚到稳定版本。
  - **存储编排**：支持持久存储管理，如 NFS、Ceph、GlusterFS 和云存储。
  - **自愈能力**：当容器崩溃或节点故障时，Kubernetes 会自动重新调度和恢复应用。
  
  #### Kubernetes 的核心组件
  1. **Master 组件**：
     - \`kube- apiserver\`：提供 Kubernetes API 接口，供用户和其他组件交互。
     - \`etcd\`：分布式键值存储，存储 Kubernetes 集群的所有数据。
     - \`kube - scheduler\`：负责将 Pod 分配到合适的节点。
     - \`kube - controller - manager\`：运行控制器（如副本控制器、节点控制器），保证集群的期望状态。
  
  2. **Node 组件**：
     - \`kubelet\`：负责与 Master 交互，管理 Pod 生命周期。
     - \`kube - proxy\`：实现 Kubernetes 服务的网络代理。
     - \`Container Runtime\`：如 Docker、containerd 或 CRI-O，负责运行容器。
  
  #### Kubernetes 的核心概念
  - **Pod**：Kubernetes 最小的部署单元，可以包含一个或多个容器。
  - **Deployment**：定义和管理 Pod 的期望状态，支持滚动更新和回滚。
  - **Service**：提供稳定的访问入口，负责负载均衡。
  - **ConfigMap & Secret**：用于管理配置信息和敏感数据。
  - **PersistentVolume (PV) & PersistentVolumeClaim (PVC)**：用于持久化存储。
  - **Ingress**：提供 HTTP/HTTPS 访问控制，通常用于暴露服务。
  
  #### Kubernetes 的优势
  1. **自动化运维**：Kubernetes 能够自动监控、修复和扩展应用程序，减少人工干预。
  2. **高可用性**：提供故障恢复和负载均衡，确保应用稳定运行。
  3. **混合云支持**：Kubernetes 可以运行在公有云、私有云和本地数据中心，实现无缝迁移。
  4. **云原生生态**：支持微服务架构，与 DevOps、CI/CD 完美结合。
  
  #### Kubernetes 与 Docker 的关系
  Kubernetes 不是替代 Docker 的工具，而是一个用来管理 Docker 容器的编排平台。
  
  | 特性        | Docker | Kubernetes |
  |------------|--------|------------|
  | **作用**    | 运行单个容器 | 管理和编排多个容器 |
  | **自动扩展** | 需要手动管理 | 支持自动扩展 |
  | **存储管理** | 依赖 Docker Volume | 提供 PV 和 PVC 管理持久存储 |
  | **网络管理** | 需要手动配置 | 提供内置的网络管理 |
  | **服务发现** | 依赖额外工具 | 内置 DNS 和 Service 发现 |
  
  ##### 总结：
  Kubernetes 是现代化云计算的核心技术，它通过自动化管理容器，实现了高效的资源利用率、强大的扩展性和稳定性，是微服务和云原生应用的理想选择。
        `,
        };
    },
});
</script>

<style scoped></style>