<template>
  <div id="reg">
    <div class="reg-container">
      <h3 class="welcome-title">用户注册</h3>
      <div id="message-bar" class="blinking-border"></div>
      <el-form :model="registerForm" label-width="80px" class="register-form">
        <el-form-item label="Email" prop="email">
          <el-input v-model.trim="registerForm.email" class="input-item"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="registerForm.password" class="input-item"></el-input>
        </el-form-item>
        <el-form-item label="密码确认" prop="confirmPassword">
          <el-input type="password" v-model="registerForm.confirmPassword" class="input-item"></el-input>
        </el-form-item>

        <p style="color: orange; font-size: 13px; text-align: left; margin: 8px 0;">单向密码加密，管理员也无法查看您密码。</p>
        <!-- <p style="color: orange; font-size: 13px; text-align: left; margin: 8px 0">
          最安全注册格式: zhangsan@azlinux.com 采用本站域名（最安全，无实际邮箱产生，本站点不存储您的任何信息！）
        </p> -->

        <el-form-item>
          <el-text type="danger" class="register-warning">注册须知: 本网站服务器资源不可用于【运维练习】以外的用途！</el-text>
        </el-form-item>
        <el-form-item class="button-container">
          <el-button type="primary" @click="register" round>同意并注册</el-button>
          <el-link type="info" href="/login">已有账号，去登录</el-link>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import { ElMessage } from "element-plus";

export default {
  components: {
  },
  setup() {
    const registerForm = ref({
      email: "",
      password: "",
      confirmPassword: "",
    });

    const getApiHost = () => {
      const hostname = window.location.hostname;
      if (hostname === 'sre.azlinux.com' || hostname === 'cn.sre.azlinux.com') {
        return 'sre.azlinux.com';
      }
      // if (hostname === 'hk.sre.azlinux.com' || hostname === 'hk.azlinux.com') {
      //   return 'hk.azlinux.com';
      // }
      return 'sre.azlinux.com';
    };

    const register = () => {
      if (!registerForm.value.email) {
        ElMessage.error({
          message: "邮箱地址不可以为空。",
          duration: 3000,
          offset: 120,
        });
        return;
      }
      if (registerForm.value.email.indexOf("@") == -1) {
        ElMessage.error({
          message: "邮箱地址缺少必要的at符号。",
          duration: 3000,
          offset: 120,
        });
        return;
      }
      if (registerForm.value.email.indexOf(".") == -1) {
        ElMessage.error({
          message: "邮箱地址缺少必要的dot符号。",
          duration: 3000,
          offset: 120,
        });
        return;
      }
      if (registerForm.value.email.length > 40) {
        ElMessage.error({
          message: "邮箱地址整体长度超过了40，请输入合法邮箱。",
          duration: 3000,
          offset: 120,
        });
        return;
      }
      if (registerForm.value.email.length < 5) {
        ElMessage.error({
          message: "请输入正确的邮箱地址。",
          duration: 3000,
          offset: 120,
        });
        return;
      }
      if (registerForm.value.email === "zhangsan@azlinux.com") {
        ElMessage.error({
          message: "这是示例账户，无法被注册，请使用其他名称。",
          duration: 5000,
          offset: 120,
        });
        return;
      }
      if (registerForm.value.email.startsWith("@")) {
        ElMessage.error({
          message: "邮箱不符合规则，不能以at开头。",
          duration: 3000,
          offset: 120,
        });
        return;
      }
      if (registerForm.value.email === "lisi@azlinux.com") {
        ElMessage.error({
          message: "这是示例账户，无法被注册，请使用其他名称。",
          duration: 5000,
          offset: 120,
        });
        return;
      }
      if (!registerForm.value.password) {
        ElMessage.error({
          message: "密码不可以为空",
          duration: 3000,
          offset: 120,
        });
        return;
      }

      if (registerForm.value.password.length < 6) {
        ElMessage.error({
          message: "密码长度至少6个字符",
          offset: 120,
        });
        return;
      }
      if (registerForm.value.password.length > 20) {
        ElMessage.error({
          message: "密码长度过长, 不可超过20个字符",
          offset: 120,
        });
        return;
      }

      if (registerForm.value.password !== registerForm.value.confirmPassword) {
        ElMessage.error({
          message: "两次输入的密码不一样!",
          offset: 120,
        });
        return;
      }
      axios
        .post(`http://${getApiHost()}:8094/register`, registerForm.value)
        .then((response) => {
          document.getElementById("message-bar").textContent =
            response.data.message + " 自动跳转到登录页面...";

          ElMessage({
            message: "【注册成功】或【账号已存在】, 2秒后跳转到登录页面...",
            type: "success",
            duration: 2000,
            offset: 120,
          });

          setTimeout(() => {
            window.location.href = "/login";
          }, 2000);
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            console.log("该邮箱已被注册");
            document.getElementById("message-bar").textContent =
              "该邮箱已被注册，请登录或申诉";
            let blinkingDiv = document.getElementById("message-bar");
            blinkingDiv.classList.add("blinking"); // 添加闪烁动画类

            blinkingDiv.addEventListener("animationend", function () {
              blinkingDiv.classList.remove("blinking");
            });
          } else {
            console.log(error.response);
            ElMessage({
              message: "网络连接失败，稍后重试，或检查网络",
              type: "error",
              duration: 3000,
              offset: 120,
            });
          }
        });
    };

    return {
      registerForm,
      register,
    };
  },
};
</script>

<style scoped>
#reg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.reg-container {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 340px;
  text-align: center;
}

h3 {
  margin: 0.4rem 0;
  color: #333;
  font-weight: bold;
}

.register-form {
  width: 100%;
}

.input-item:focus-within {
  border-color: #409eff;
  box-shadow: 0 0 8px rgba(64, 158, 255, 0.3);
  transition: box-shadow 0.3s ease;
}

.el-text {
  font-weight: 600;
  font-size: 13px;
  text-align: left;
  color: #f56c6c;
}

.button-container {
  text-align: right;
}

.el-button {
  width: 100%;
  padding: 0.32rem 0;
}

#message-bar {
  /* background-color: #f5f5f5; */
  text-align: center;
  padding: 0.4rem;
  font-family: "Microsoft YaHei", "KaiTi", "楷体", "微软雅黑", sans-serif, serif;
  font-size: 0.9rem;
  color: orangered;
  margin: 0.4rem 0rem;
}

.blinking-border {
  border: 2px solid transparent;
  padding: 20px;
  text-align: center;
}

.blinking {
  animation: blinkBorder 1.5s 3;
}

@keyframes blinkBorder {

  0%,
  100% {
    border-color: transparent;
  }

  50% {
    border-color: orangered;
  }
}

.welcome-title {
  background-color: #e0e0e0;
  color: black;
  text-shadow: none;
  transition: background-color 0.3s ease;
  padding: 10px 6px;
}

.el-link {
  font-size: 12px;
  margin-top: 4px;
  ;
}
</style>
